import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const DigitizeDocuments = () => (
  <Layout>
    <SEO title="Digitize Documents" />
    <div className="page-content">
      <div className="container">
        <h1 className="text-blue-main">Digitize Documents</h1>
        <div className="row">
          <div className="col-md-8">
            <p>
              Deal with a huge amount of paper documents without breaking a
              sweat. Transform all of the information they contain into
              digitized documents, which can be accessed and processed anywhere
              and any time.
            </p>
            <p>
              Extract all the data you need from these paper files and have them
              organized quickly and smoothly with the help of advanced OCR
              technology.
            </p>
            <p>
              These hundreds of files will be easily searchable. You can also
              scan large-format documents as well as blueprints and plot plans.
              We also offer scanning of books and even historical records. Make
              sure that all the business data that you have are kept safe.
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="my-3">Document Digitization Services</h2>
            <p>
              Aside from everything mentioned above, we also offer the following
              services and we can tailor-make solutions for your needs.
            </p>
            <ul className="ml-3">
              <li>Custom or Confidential Processing</li>
              <li>Microfilm and Microfiche Conversion</li>
              <li>Invoice Digital Processing</li>
              <li>Human Resource Documents Processing</li>
              <li>Speedy Acquisition Processes</li>
            </ul>
            <hr class="my-5" />
            <p className="font-weight-bold">Why Digitize Your Documents?</p>
            <p>There are many reasons why you should go the digital route.</p>
            <p>
              First off, paper documents age. They can tear, get damaged, or get
              lost. To protect the integrity of the information, wouldn’t it be
              better and safer if you have digital copies of all the data? On
              top of that, paper files take up a lot of space. You don’t have to
              purchase a lot of filing cabinets nor fill up loads of drawers
              when you digitize your business documents.
            </p>
            <p>
              Scanning these files will also help you gather all the data in one
              place and organize them as best as you can. Easily search your oil
              and gas records or find what you need within lease documents
              without spending so much time. Extract information and maximize
              its value.
            </p>
            <p>
              To know more about our document digitization services, reach us at
              <a
                href="mailto:info@pacificcoastls.com"
                className="text-blue-main mx-1"
              >
                info@pacificcoastls.com
              </a>
              or
              <a href="tel:303.223.1665" className="text-blue-main mx-1">
                720.234.1155
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default DigitizeDocuments
